body {
  background: #e7e8e3;
}
form.loginForm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
}
input[type=text], input[type=password] {
  width: 260px;
  padding: 10px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid black;
  border-radius: 5px;
}
.loginBtn {
  background-color: #000;
  border: none;
  color: white;
  padding: 12px 20px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.header {
  align-items: center;
  display: flex;
  min-height: 84px;
  padding: 10px 40px 10px 40px;
}
.logo h1 {
  color: #ae2b42;
  text-transform: uppercase;
}
.header .logo h1 {
  padding-left: 25px;
}
.formInput {
  display: flex;
  flex-direction: column;
}
.error_h {
  visibility: hidden;
}
label.error  {
  color: #ae2b42;
  min-height: 25px;
}
.errorfocus{
  border-color:#ae2b42 !important;
}
div#error_page {
  text-align: center;
  margin-top: 25px;
}
div#error_page h1 {
  margin: 0;
  font-size: 150px;
  line-height: 150px;
  font-weight: bold;
}
div#error_page p:nth-child(2) {
  margin-top: 20px;
  font-size: 30px;
}
a.logo_anchor {
  text-decoration: none;
}



/* table */

 table{
  width: 100%;
}
table{
  border-collapse: collapse;
}
table.list{
  width: 100%;
}
td, th{
  border: 1px solid #03A9F4;
  text-align: left;
  padding: 8px;
  text-transform: uppercase;
}
tr:nth-child(even),table.list thead>tr{
  background-color: #e7e8e3;
}
.projectdeatils input[type=text],.projectdeatils input[type=number],.projectdeatils input[type=url] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}
form div.form-action-buttons{
  text-align: right;
}
a{
  cursor: pointer;
  text-decoration: underline;
  color: #0000ee;
  margin-right: 4px;   
}
label.validation-error{
  color: red;
  margin-left: 5px;
}
.hide{
  display: none;
}

.projectdeatils {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 50px;
}
.createsitelist, .tablewrapper {
  width: 100%;
}
.formsubmit {
  background-color: #000;
  border: none;
  color: white;
  padding: 12px 20px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}



/* popup */

:root {
  --primary: #0E2431;
  --secondary: #4070F4;
  --secondary-dark: #3665EB;
  --gray: #666;
  --body-font: 'Nunito', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--body-font) !important;
}



.confirm {
  width: 360px;
  height: 280px;
  background: #fff;
  transition: .6s;
  z-index: 100;
  position: absolute;
  top: -280px;
  left: calc(50% - 180px);
  padding: 10px;
}

.confirm.show {
  top: calc(50% - 140px);
}

.confirm-content {
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary);
  padding: 1.5rem;
}

.confirm-content h4 {
  font-size: 1.2rem;
  font-weight: 800;
  position: relative;
}

.confirm-content h4::after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--secondary);
  position: absolute;
  bottom: -7px;
  left: 0;
}

.confirm-content h2 {
  font-size: 2rem;
  font-weight: 300;
  margin-top: 3rem;
}

.confirm-content p {
  font-size: 1.1rem;
  color: var(--gray);
  text-align: center;
  margin-top: .5rem;
}

.confirm-btns {
  height: 30%;
}

.confirm-btns button {
  width: 35%;
  height: 40px;
  background: #ae2b42d9;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 0 auto;
}

.confirm-btns button:hover {
  background: var(--secondary-dark);
}

.overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .5);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.confirm.show + .overlay {
  display: block;
}

.button {
  background: var(--secondary);
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  padding: 1rem 4rem;
}

@media screen and (max-width: 450px) {
  .confirm {
    width: 300px;
    left: calc(50% - 150px);
  }
}
.confirm.show + .overlay {
  display: block;
}

.confirm-btns {
  display: flex;
  gap: 20px;
}
.form_title {
  text-align: left;
  margin: 50px 0 0 0;
  padding-left: 50px;
}
.confirm-content .inner {
  text-align: center;
}
.delite {
  background-color: #ae2b42d9;
  border: none;
  color: white;
  padding: 12px 20px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}
.openlink,.display_title {
  text-transform: lowercase;
}
.confirm-content {
  margin-bottom: 25px;
}
.openlink {
  text-decoration: none;
}
.norecode {
  margin-top: 35px;
  text-align: center;
}
.loaderdiv {
  text-align: center;
}
.logo img {
  width: 290px;
}
.createsitelist {
  width: 40%;
}
.tablewrapper {
  width: 60%;
}
@media screen and (max-width: 450px) {
  .confirm {
    width: 300px;
    left: calc(50% - 150px);
  }

}

@media screen and (max-width: 768px) {

  .createsitelist, .tablewrapper {
    width: 100%;
    overflow: auto;
}
.projectdeatils {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  padding: 10px;
  flex-direction: column;
}
form.loginForm {
  flex-direction: column;
}
.form_title {
  text-align: center;
  margin: 50px 0 0 0;
  padding-left: 0px;
}
.header {
  justify-content: center;
  padding: 10px 1px 10px 1px;
}
}

