body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.container_listing {
  align-items: center;
  display: flex;
  /* justify-content: center; */
  position: relative;
  margin-top: 0px;
  width: 100%;
  height: 115px;
  margin-bottom:10px;
}

.context_listing {
  width: 100%;
  padding-left: 30px;
}


.button_listing {
  width: 100%;
  left: 0;
  text-align: end;
  opacity: 0;
  transition: opacity .35s ease;
}

.button_listing button {
  width: 100px;
  height: 34px;
  padding: 0;
  text-align: center;
  color: white;
  border: solid 2px white;
  z-index: 1;
}

.container_listing:hover .button_listing {
  opacity: 1;
}

.viewbtn {
  background: #409cb20a;
  border-radius: 5px;
}
.btnwrapper {
  padding: 0px 15px 0px 15px;
}
.btnwrapper {
  display: flex;
  gap: 25px;
  align-items: center;
  /* justify-content: end; */
}
.context_listing .site_name {
  color: #fff;
}
button.viewbtn a.openlink {
  color: #fff;
  font-size: 15px;
}
.successmessage {
  background: green;
  width: 80%;
  border-radius: 4px;
}
.successmessage p {
  color: white;
  padding: 10px;
}

@media only screen and (max-width: 900px) {
  .btnwrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    /* justify-content: end; */
    flex-direction: column;
  }
  .context_listing {
    padding-left: 20px;
}
.button_listing button {
  width: 120px;
  height: 40px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  color: white;
  border: solid 2px white;
  z-index: 1;
}
}
